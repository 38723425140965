.textField {
    display: flex;
    flex-direction: column;
    flex: 1;
    
    input {
        // outline: 1px solid #123;
        // outline: none;
        font-size: large;
        // background-color: aquamarine;
        transition: 0.3s all;
        
        &:focus {
            // border: 2px solid #1ac;
        }
    }    
}
#BoxProductComposition{
    .container{
        margin-bottom: 16px;
        h3{
            color: var(--primary);
        }
        .obrigatorio{
            color: #d00;
            font-size: smaller;
            text-align: right;
            transition: color 0.3s ease-in-out;

        }
        .obrigatorioChecked{
            @extend .obrigatorio;
            color: #666;
            font-size: smaller;
            text-align: right;
        }
    }
}


.float {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 12px;
    z-index: 10000;
    left: 0;
    padding-top: 44px;
    background-color: #fff;
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 0px 6px 0px #0005;
    .closeButton {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1000;
    }
}

#Keyboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    // gap: 16px;
    width: 100%;
    max-height: 400px;
    height: calc(50vh - 164px);
    // background-color: #1ac;

    p{
        margin: 0;
    }
    .container {
        max-width: 1000px;
        width: 100%;
        height: 100%;
        gap: 16px;
        display: grid;
        grid-template-columns: repeat(3, 1fr); // Define 3 columns with equal width

        // .line {
        //     display: flex;
        //     flex: 1;
        //     flex-direction: row;
        //     gap: 16px;

        .button {
            // background-color: #ddd;
            display: flex;
            // height: 60px;
            // border: #001537 2px solid;
            box-shadow: 2px 2px 10px -5px #555;
            // width: 120px;
            // height: 4rem;
            align-items: center;
            justify-content: center;
            text-align: center;
            vertical-align: middle;
            border-radius: 12px;
            transition: all 0.2s ease-in-out;
            cursor: pointer;

            &:active {
                background-color: #aaa;
                transform: scale(1.1);
            }
        }

        .buttonClear {
            @extend .button;
        }

        .buttonEnter {
            @extend .button;
            // border: #399c54 2px solid !important;
            // background-color: #399c54;
            // background-color: #bbe7cf;

            .enter {
                color: #fff;
                font-size: 1.5rem;
            }
        }
        // }
    }

    .querty {
        display: flex;
        flex-direction: column;
        // background-color: aqua;
        align-items: center;
        gap: 4px;
        height: 100%;
        width: 100%;
        .line {
            width: 100%;
            max-width: 1200px;
            display: flex;
            flex: 1;
            gap: 4px;
            .button {
                display: flex;
                flex: 1;
                // border: #001537 2px solid;
                // max-width: 10rem;
                padding: 0;
                box-shadow: 2px 2px 10px -5px #555;
                // width: 120px;
                // max-height: 40px;
                // min-height: 40px;
                align-items: center;
                justify-content: center;
                text-align: center;
                vertical-align: middle;
                border-radius: 8px;
                transition: all 0.2s ease-in-out;
                font-size: x-large;
                &:active {
                    background-color: #aaa;
                    transform: scale(1.1);
                }
            }
            .empty {
                flex: 1;
            }
            .buttonShift {
                @extend .button;
                max-width: unset;
                flex: 2;
            }
            .buttonEnter {
                @extend .button;
                max-width: unset;
            }
            .buttonClear {
                @extend .button;
                max-width: unset;
            }
            .buttonSpace {
                @extend .button;
                max-width: unset;
                flex: 3;
            }
        }
    }
}

.inputContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    label {
        font-size: 1rem;
        font-weight: 500;
        color: #001537;
    }
    input {
        font-size: x-large;
    }
}

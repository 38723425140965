#Empty {
  display: flex;
  align-items: center;
  justify-content: center;

  >h2 {
    margin-left: 15px;
    text-align: center;
    color: #BDBDBD;
    font-size: 1.5rem;
  }
}
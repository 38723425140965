#CartView {
    display: flex;
    flex: 1;
    // background-color: burlywood;
    flex-direction: column;
    overflow: hidden;

    > h2 {
        border-bottom: 2px solid #5f5f5f;
        padding: 0;
        margin: 0;
        margin-bottom: 0;
        color: #5f5f5f;
    }

    .container {
        padding-top: 8px;
        padding-bottom: 8px;
        display: flex;
        overflow: auto;
        flex: 1;
        flex-direction: column;
        gap: 8px;

        .cartEmpty {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .cartItem {
            border: 1px solid #bdbdbd;
            display: flex;
            flex-direction: column;
            padding: 8px;
            border-radius: 8px;
            animation-name: fade;
            animation-duration: 0.2s;

            .main {
                display: flex;
                gap: 16px;
            }

            .accordeon {
                background-color: #efefef01;
                padding: 8px;
                border-radius: 8px;
                margin-top: 8px;
                font-size: small;
                .composition {
                    display: flex;
                    // background-color: #bdbdbd;
                    flex-direction: column;
                    label{
                        font-weight: 500;
                    }
                    span{
                        padding: 4px;
                    }
                }
            }
            .cartItemContent {
                display: flex;
                flex: 1;
                gap: 8px;
                align-items: center;
                .img {
                    border-radius: 8px;
                    width: 60px;
                    height: 60px;
                }

                span {
                    display: flex;
                    flex: 1;
                    text-align: left;
                }
            }

            .quantityContainer {
                display: flex;
                flex: 0;
                justify-content: space-between;
                align-items: center;

                .buttonQuantity {
                    background-color: #ed0029;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    border-radius: 16px;
                    padding: 4px;
                    height: 25px;
                    width: 25px;
                }

                .valueQuantity {
                    width: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

    .container::-webkit-scrollbar {
        width: 7px;
    }

    .container::-webkit-scrollbar-track {
        background: transparent;
    }

    .container::-webkit-scrollbar-thumb {
        background: #bdbdbd;
        border-radius: 5px;
    }

    .container::-webkit-scrollbar-thumb:hover {
        background: #bdbdbd;
    }

    .cartResumoDisabled {
        display: flex;
        padding: 0;
        flex-wrap: wrap;
        background-color: #5f5f5f;
        opacity: 0.5;
        color: #fff;
        padding: 8px;
        gap: 8px;
        border-radius: 8px 8px 0px 0;

        .clearCartButton {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            transition: 0.2s all;
            cursor: pointer;
            visibility: hidden;

            &:active {
                transform: scale(1.1);
            }

            > span {
                font-size: 1rem;
                margin-right: 5px;
            }
        }

        .resume {
            display: flex;
            flex: 1;
            min-width: 150px;
            flex-direction: column;
            text-align: right;

            b {
                font-size: 1.5rem;
            }
        }

        .sendButton {
            display: flex;
            background-color: #bdbdbd;
            flex: 1;
            min-height: 40px;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            transition: 0.2s all;
            cursor: none;
            color: #fff !important;
        }
    }

    .cartResumo {
        display: flex;
        padding: 0;
        flex-wrap: wrap;
        background-color: #001537;
        color: #fff;
        padding: 8px;
        gap: 8px;
        border-radius: 8px 8px 0px 0;

        .clearCartButton {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            transition: 0.2s all;
            cursor: pointer;

            &:active {
                transform: scale(1.1);
            }

            > span {
                font-size: 1rem;
                margin-right: 5px;
            }
        }

        .resume {
            display: flex;
            flex: 1;
            min-width: 150px;
            flex-direction: column;
            text-align: right;

            b {
                font-size: 1.5rem;
            }
        }

        .sendButton {
            display: flex;
            background-color: #ed0029;
            flex: 1;
            min-height: 40px;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            transition: 0.2s all;
            cursor: pointer;

            &:active {
                transform: scale(1.1);
            }
        }
    }
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

#Toolbar {
    display: flex;
    background-color: #333;
    color: #fff;
    height: 50px;
    .container {
        display: flex;
        flex: 1;
        justify-content: space-between;
        .start {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px;
            gap: 16px;
            // background-color: #cfdeef;
        }
        .center {
            gap: 16px;

            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            img {
             object-fit: contain;
             height: 50px;
            }
        }
        .end {
            gap: 16px;

            display: flex;
            flex: 0;
            padding: 8px;
            justify-content: center;
            align-items: center;
            // background-color: coral;
        }
        .button {
            transition: all 0.2s;
            padding: 0 8px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            &:hover {
                transform: scale(1.1);
            }
            &:active {
                transform: scale(1.1);
            }
        }
    }
}

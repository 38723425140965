#OperatorAndOrderPadForm {
    display: flex;
    flex-direction: row;
    height: 100%;
    flex-wrap: wrap;

    .formContainer {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        background-color: #f0f0f0;
        padding: 16px;
        min-width: 300px;

        .form {
            display: flex;
            flex: 1;
            flex-direction: column;
            max-width: 400px;
            gap: 32px;
            .logo {
                text-align: center; 
                img {
                    display: flex;
                    object-fit: contain;
                    max-width: 400px;
                    border-radius: 8px;
                }
            }

            .inputContainer {
                display: flex;
                flex: 1;
                // border: 3px solid transparent ;
            }

            .inputFocused {
                @extend .inputContainer;
                background-color: #ddd;
                // border: 3px solid #ddd ;
                border-radius: 6px;
            }
        }
    }

    .keyboardContainer {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        padding: 16px;
        min-width: 300px;
    }
}

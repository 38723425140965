#CatalogPage {
    height: 100vh;
    display: flex;
    flex-direction: column;
    .toolbar {
        height: 50px;
    }
    .container {
        height: calc(100% - 90px);
        display: flex;
        flex: 1;
        .catalogListContainer {
            display: flex;
            flex: 2;
            // background-color: aquamarine;
        }
        .cartContainer {
            display: flex;
            flex: 1;
            flex-direction: column;
            padding: 8px;
            padding-bottom: 0;
            // background-color: aqua;
        }
    }
}

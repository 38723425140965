#ProductDetail {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    #Header {
        background-color: var(--primary);
        .container {
            background-color: #fff;
            position: relative;
            height: 100px;
            width: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
        .title {
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--onPrimary);
            height: 60px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
        }
        .backButton {
            position: fixed;
            color: #fff;
            background-color: #0005;
            top: 8px;
            left: 8px;
            z-index: 1;
        }
    }

    .container {
        display: flex   ;
        flex-direction: column;
        position: relative;
        background-color: var(--background);
        .description {
            h2 {
                margin-top: 0;
                color: var(--primary);
            }
        }

        .price {
        }
        .footerContainer {
            display: flex;
            flex: 1;
            align-items: center;
            margin-top: 8px;
            .footer {
                display: flex;
                flex: 1;
                gap: 16px;
                background-color: var(--background);
                animation: inAnimationFooter 0.5s ease-in-out;
                .quantity {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 120px;
                    .buttonIcons {
                        color: var(--primary);
                        // box-shadow: 2px 2px 2px 0px #0002;
                        background-color: #fff;
                        transition: opacity 0.3s;
                    }

                    .disabledIcon {
                        @extend .buttonIcons;
                        opacity: 0.2;
                    }
                }
                .buttonContainer {
                    display: flex;
                    flex: 1;
                }
            }
        }
    }
}

// @keyframes inAnimationFooter {
//     from {
//         transform: translatey(60px);
//         // opacity: 0;
//     }
//     to {
//         transform: translatey(0px);
//         // opacity: 1;
//     }
// }

#CatalogList {
    display: flex;
    flex: 1;
    height: 100%;
    background-color: #F0F0F0;

    .containerBase {
        padding: 8px;
        display: flex;
        flex-direction: column;
        min-width: 150px;
        font-size: medium;
        gap: 8px;
        overflow: auto;
    }

    .containerBase::-webkit-scrollbar {
        width: 7px;      
    }
    .containerBase::-webkit-scrollbar-track {
        background: transparent;
    }

    .containerBase::-webkit-scrollbar-thumb {
        background: #bdbdbd;
        border-radius: 5px;        
    }

    .containerBase::-webkit-scrollbar-thumb:hover {
        background: #bdbdbd;
    }

    .itemBase {
        display: flex;
        transition: all 0.2s;
        border-radius: 8px;
        animation-name: fade;
        animation-duration: 0.2s;
        padding: 12px;
        cursor: pointer;

        &:active {
            background-color: #CFDEEF;
        }

        &:hover {
            background-color: #CFDEEF;
        }
    }

    .itemSelected {
        @extend .itemBase;
        color: #fff;
        background-color: #001537;

        &:active {
            background-color: #001537;
        }

        &:hover {
            background-color: #001537;
        }
    }

    .storeContainer {
        @extend .containerBase;
        // background-color: #aaa;
        flex: 1;

        .storeItem {
            @extend .itemBase;
            padding: 16px;
        }
    }

    .categoryContainer {
        @extend .containerBase;
        background-color: #F0F0F0;
        flex: 2;

        .categoryItem {
            @extend .itemBase;
            transition: 0.2s all;
            color: #5F5F5F;
            font-size: 1.2rem;

        }

        .categoryItemSelected {
            @extend .itemSelected;
            color: #FFF;
            font-size: 1.2rem;
        }
    }

    .productsContainer {
        @extend .containerBase;
        flex: 3;
        min-width: 400px;

        .productItem {
            @extend .itemBase;
            border: 1px solid #BDBDBD;
            padding: 8px;
            border-radius: 8px;
            justify-content: space-between;

            gap: 8px;

            .img {
                width: 60px;
                height: 60px;
                border-radius: 8px;
            }

            span {
                flex: 1;
                // background-color: antiquewhite;
                display: flex;
                align-items: center;
            }

            b {
                display: flex;
                justify-content: center;
                align-items: center;
                color: #5F5F5F;
                font-size: 1.2rem;
                // background-color: antiquewhite;
            }
        }
    }
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
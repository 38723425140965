#NumeriKeyboard {
    .container {
        // background-color: #1ac;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;

        .line {
            display: flex;
            flex-direction: row;
            gap: 16px;

            .button {
                // background-color: #ddd;
                display: flex;
                border: #001537 2px solid;
                width: 80px;
                height: 80px;
                align-items: center;
                justify-content: center;
                text-align: center;
                vertical-align: middle;
                border-radius: 50%;
                font-size: x-large;
                transition: all 0.2s ease-in-out;
                cursor: pointer;

                &:active {
                    background-color: #aaa;
                    transform: scale(1.1);
                }

                .number {
                    color: #001537;
                    font-size: 2.5rem;
                }
            }

            .buttonClear {
                @extend .button;
                border: #ED0029 2px solid !important;
                img {
                    width: 55px !important;                   
                } 
            }

            .buttonEnter {
                @extend .button;
                border: #399C54 2px solid !important;
                .enter {
                    color: #399C54;
                    font-size: 2.5rem;
                }                
            }
        }
    }
}
#ExtractResume {
    display: flex;
    padding: 16px;
    border: 1px solid #0002;
    border-radius: 8px;
    margin-bottom: 8px;


    .container {
        >button {
            text-transform: none;
            color: #32008E;
            >svg {
                font-size: 16px;
            }
        }

        .user {
            padding-bottom: 8px;

            .name {
                color: #5F5F5F;
                font-size: 1.2rem;
            }

            .orderPad {
                display: flex;

                >span {
                    display: flex;
                    align-items: center;
                    flex: 1;
                    color: #5F5F5F;
                    font-size: 1.2rem;

                    >b {
                        margin-left: 5px;
                        color: #5F5F5F;
                        font-size: 1.2rem;
                    }
                }
            }

            span {
                display: flex;
                flex: 1;
            }
        }

        .data {
            display: flex;
            gap: 8px;
            flex-wrap: wrap;

            .item {
                display: flex;
                flex: 1;
                flex-direction: column;
                border: 1px solid #0002;
                font-size: small;
                min-width: 100px;
                border-radius: 4px;
                padding: 4px;

                span {
                    font-size: medium;
                }

                label {
                    font-size: smaller;
                }
            }
        }
    }
}

#ExtracLoading {
    display: flex;
    padding: 8px;
    border: 1px solid #0002;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    height: 150px;
    animation: fade 1s infinite;
    background-color: #0001;
}

#ExtracNotFound {
    display: flex;
    padding: 8px;
    border: 1px solid #0002;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    height: 150px;
    // animation: fade 1s infinite;
    background-color: #0001;
    flex-direction: column;

    .readOrderPadTagButton {
        padding: 8px 32px;
        background-color: #ED0029;
        color: #fff;
        border-radius: 8px;
        display: flex;
        cursor: pointer;

        &:hover,
        &:active {
            background-color: #a00d1e;
        }
    }
}

@keyframes fade {
    0% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.5;
    }
}
#ProductCompositionItem {
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--border-box);
        padding: 4px;
        animation: inAnimation 0.5s ease-in-out;
        .start {
            display: flex;
            align-items: center;
            .description {
                text-align: left;
                b {
                    margin-left: 4px;
                    font-weight: 400;
                    font-size: 12px;
                    // color: green;
                    opacity: 0.5;
                }
            }
        }
        .end {
            .totalPriceContainer {
                min-width: 50px;
                display: flex;
                justify-content: flex-end; 
                // background-color: aqua;
                .totalPrice {
                    color: var(--primary);
                    font-size: small;
                    animation: inAnimationPrice 0.3s ease-in-out; 
                }
            }
            display: flex;
            align-items: center;

            .total {
            }
            .quantitiy {
                width: 115px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                // background-color: aqua;
            }
            .selector {
                margin-left: 8px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                // background-color: aqua;
            }
            .buttonIcons {
                color: var(--primary);
                // box-shadow: 2px 2px 2px 0px #0002;
                background-color: #fff;
                transition: opacity 0.3s;
            }

            .disabledIcon {
                @extend .buttonIcons;
                opacity: 0.2;
            }
        }
    }
}

@keyframes inAnimationPrice {
    from {
        transform: translatex(-40px);
        opacity: 0;
    }
    to {
        transform: translatex(0px);
        opacity: 1;
    }
}
@keyframes inAnimation {
    from {
        transform: translatey(-100px);
        opacity: 0;
    }
    to {
        transform: translatex(0px);
        opacity: 1;
    }
}

.fullscreenToast {
  display: flex;
  min-height: 100vh;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .text {
    span {
      color: #fff;
      font-size: 36px;
    }
    animation: fadeText 1s;
  }
  .icon {
    span {
      color: #fff;
      font-size: 64px;
    }
    animation: fade 1s;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeText {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  30% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

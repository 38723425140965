#ExtractOrders {
    display: flex;
    flex-direction: column;
    flex: 1;
    .container {
        max-height: calc(100vh - 32px);
        width: 600px;
        padding: 16px;
        // background-color: #5f5f5f;
        display: flex;
        flex-direction: column;
        // background-color: #001537;
        .textData {
            color: #333333;
            font-size: 0.75rem;
            margin: 0;
        }

        .headerTitle {
            display: flex;
            align-items: center;
            justify-content: space-between;

            > h2 {
                color: #333333;
                font-size: 1.75rem;
                margin: 0;
            }

            svg {
                font-size: 1.75rem;
                color: #333333;
                cursor: pointer;
            }
        }

        .containerOrder {
            overflow: auto;
            display: flex;
            flex-direction: column;
            .orderContent {
                background-color: #eee;
                margin-bottom: 8px;
                padding: 8px;
                border-radius: 8;
                .orderItemContainer {
                    .orderItemHeader {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .label {
                            color: #333333;
                            margin: 4px 0;
                        }

                        .value {
                            font-size: 1rem;
                            color: #333333;
                            margin: 4px 0;
                        }
                    }
                    .additionalsContainer {
                        padding: 4px;
                        font-size: small;
                        border: 1px dotted #0002;
                        border-radius: 8px;
                        .contentAddicional {
                            .contentAddicionalItem {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                            }

                            label {
                                font-weight: 500;
                            }
                            p {
                                margin: 0;
                                color: #5f5f5f;
                            }
                        }
                    }
                }
                .orderTotal{
                    display: flex;
                    justify-content: space-between;
                    font-size: large;
                    // background-color: #5f5f5f;
                }
            }
        }

        .containerOrder::-webkit-scrollbar {
            width: 7px;
        }

        .containerOrder::-webkit-scrollbar-track {
            background: transparent;
        }

        .containerOrder::-webkit-scrollbar-thumb {
            background: #bdbdbd;
            border-radius: 5px;
        }

        .containerOrder::-webkit-scrollbar-thumb:hover {
            background: #bdbdbd;
        }

        .orderItemContainer:last-child {
            border-bottom: 2px solid #bdbdbd;
            margin-bottom: 16px;
        }

        .resumeContainer {
            background-color: #001537;
            border-radius: 16px;
            padding: 16px;

            .contentResume {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .label {
                    color: #fff;
                    margin: 8px 0;
                }

                .value {
                    color: #fff;
                    margin: 8px 0;
                }

                .labelTotal {
                    @extend .label;
                    font-size: 1.25rem;
                }

                .valueTotal {
                    @extend .value;
                    font-weight: bold;
                    font-size: 1.25rem;
                }
            }
        }
    }
}

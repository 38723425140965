#FirstOpenPage {
    background-color: #F0F0F0;
    height: 100vh;

    .containerMac {
        display: flex;
        height: calc(100vh - 50px);
        justify-content: center;
        align-items: center;

        .content {
            max-width: 470px;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            display: flex;
            flex: 1;
            gap: 32px;

            >p {
                font-size: 1.2rem;
            }

            .imageLogo {
                padding-bottom: 50px;
                width: 250px;
            }

            button {
                background-color: #ED0029;
                color: #FFF;
                min-height: 56px;
                text-transform: none;
                border-radius: 16px;
                transition: 0.2s all;
                cursor: pointer;
                width: 100%;
            }
        }

    }

}

.containerDialog {
    >div {
       > div {
            border-radius: 32px;
            padding: 16px;
        }
      
    }

    .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        gap: 8px;


        .confirm {
            background-color: #ED0029;
            color: #FFF;
            min-height: 56px;
            text-transform: none;
            border-radius: 16px;
            transition: 0.2s all;
            cursor: pointer;
            width: 100%;
        }
        .cancel {
            border-color: #ED0029;
            color: #ED0029;
            min-height: 56px;
            text-transform: none;
            border-radius: 16px;
            transition: 0.2s all;
            cursor: pointer;
            width: 100%;
        }
    }

}